import logo from './logo.svg';
import './App.css';
import SnapCamera from './pages/SnapCamera/SnapCamera';
function App() {
  return (
    <div className="App">
     <SnapCamera/>
    </div>
  );
}

export default App;
